import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Form, FormGroup, Label, Input, Button, Container } from 'reactstrap'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import * as data from 'data'
import * as loginStore from 'slices/auth/login/reducer'
import {
  adminFlields,
  dateFields,
  selectFields,
  selectFieldsOptions,
} from 'common/utils'
import toastr from 'toastr'
import * as store from '../../slices'
// Add the Firebase products that you want to use

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required'),
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  phoneNumber: Yup.string()
    .matches(/^[0-9]+$/, 'Phone number must be only digits')
    .min(10, 'Phone number must be at least 10 digits long')
    .required('Phone number is required'),
})

const EditUser = () => {
  const navigate = useNavigate()
  const { userId } = useParams()
  const authUser: any = useSelector((s: any) =>
    store.user.selectors.getOneById(s, { id: userId })
  )
  const isUserAdmin = useSelector(loginStore.selectors.isAdmin)
  useEffect(() => {
    if (!isUserAdmin) {
      navigate('/dashboard')
    }
  }, [isUserAdmin, navigate])
  const [user, setUser] = useState<any>({
    email: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    stripeCustomerId: '',
    ...authUser,
  })
  const dispatch = useDispatch()
  //   const history = useHistory()
  const formik = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      stripeCustomerId: '',
      ...user,
    },
    validationSchema,
    onSubmit: async (values: any) => {
      if (!userId) {
        return
      }
      Object.keys(values).forEach((key) => {
        if (Object.keys(adminFlields).indexOf(key) === -1) {
          delete values[key]
        }
      })

      console.log('values', values)

      data.user
        .put(userId, values)
        .then((res) => {
          toastr.success('User updated successfully')
          dispatch(store.user.fetchUsers() as any)
          console.log('res', res)
        })
        .catch((err) => {
          console.log('err', err)
        })
    },
  })

  const updateStripeInfo = () => {
    console.log('-----------------------------------')
    console.log('updateStripeInfo')

    if (!userId) {
      return
    }
    data.user
      .post('stripe-data', { userId })
      .then((res) => {
        toastr.success('Stripe info updated successfully')
        dispatch(store.user.fetchUsers() as any)
        console.log('res', res)
      })
      .catch((err) => {
        console.log('err', err)
      })
  }

  const getFormType = (key: string) => {
    if (dateFields.indexOf(key) !== -1) {
      return 'date'
    }
    if (selectFields.indexOf(key) !== -1) {
      return 'select'
    }

    return 'text'
  }
  const getValue = (key: string) => {
    if (dateFields.indexOf(key) !== -1) {
      return formik.values[key]
        ? new Date(formik.values[key]).toISOString().split('T')[0]
        : null
    }
    return formik.values[key]
  }

  // console.log('formik', formik.values)

  return (
    <Container style={{ padding: 50, marginBottom: 100 }}>
      <h1 style={{ marginTop: 100 }}>Modification de l'utilisateur</h1>
      <Form onSubmit={formik.handleSubmit}>
        {Object.keys(adminFlields).map((fieldKey) => (
          <FormGroup key={fieldKey}>
            <Label for={fieldKey}>{adminFlields[fieldKey]}</Label>
            <Input
              type={getFormType(fieldKey)}
              name={fieldKey}
              id={fieldKey}
              value={getValue(fieldKey)}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              invalid={formik.touched[fieldKey] && !!formik.errors[fieldKey]}
            >
              {Object.keys(selectFieldsOptions).indexOf(fieldKey) !== -1
                ? Object.values(selectFieldsOptions[fieldKey]).map(
                    (optionValue: any) => (
                      <option value={optionValue} key={optionValue}>
                        {optionValue.toUpperCase()}
                      </option>
                    )
                  )
                : null}
            </Input>
          </FormGroup>
        ))}
        <Button
          loading={formik.isSubmitting}
          disabled={!formik.dirty || !formik.isValid || formik.isSubmitting}
          type="submit"
          color="primary"
        >
          Update User
        </Button>
      </Form>
      <Button onClick={updateStripeInfo} color="primary">
        Mettre à jour les infos stripe
      </Button>
    </Container>
  )
}

export default EditUser
