/* eslint-disable max-len */
// import React, { useState } from 'react'
import toastr from 'toastr'
import React, { useEffect } from 'react'
import { Container } from 'reactstrap'

import { PriceTable } from 'pages/PriceTable/PriceTable'

const Plan = () => {
  const params = new URLSearchParams(window.location.search)
  const redirectStatus = params.get('redirect_status')

  useEffect(() => {
    if (!redirectStatus) {
      return
    }
    if (redirectStatus === 'succeeded') {
      toastr.success('Plan updated successfully!', 'Success')
    } else {
      toastr.error('Error updating plan!', 'Error')
    }
  }, [redirectStatus])

  // // meta title
  document.title = 'Pricing'

  return (
    <div className="page-content">
      <Container fluid>
        <PriceTable />
      </Container>
    </div>
  )
}
export { Plan }
