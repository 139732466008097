import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useQuery } from '@tanstack/react-query'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import * as data from 'data'
import * as Store from 'slices'
import toastr from 'toastr'
import Select from 'react-select'

interface AffilizSelectorProps {
  value: string
  supplierId: string
  onCancel: () => void
  onSuccess: () => void
}

export const AffilizSelector: React.FC<AffilizSelectorProps> = ({
  value,
  supplierId,
  onCancel,
  onSuccess,
}) => {
  const [selectedValue, setSelectedValue] = useState(value)

  // Get all suppliers to filter out already linked affiliz IDs
  const suppliers = useSelector(Store.suppliers.selectors.getAllCollection)
  const linkedAffilizIds = suppliers
    .map((supplier: any) => supplier.affilizId)
    .filter(Boolean)

  // Fetch affiliz data
  const { data: affilizData } = useQuery<any>({
    queryKey: ['supplierAffiliz'],
    queryFn: () =>
      data.supplier.get('affiliz').then((res) =>
        res.data.reduce((acc, item) => {
          acc[item._id] = item
          return acc
        }, {})
      ),
  })

  const availableAffilizSuppliers = Object.values(affilizData || {}).filter(
    (supplier: any) =>
      !linkedAffilizIds.includes(supplier._id) || supplier._id === value
  )

  const handleSave = async () => {
    try {
      await data.supplier.put('affiliz', {
        supplierId,
        affilizId: selectedValue,
      })
      toastr.success('Fournisseur affiliz lié avec succès')
      setSelectedValue('')
      onSuccess()
    } catch (error) {
      toastr.error('Erreur lors de la liaison du fournisseur affiliz')
      console.error(error)
    }
  }

  return (
    <Modal isOpen toggle={onCancel} size="lg">
      <ModalHeader toggle={onCancel}>
        Sélectionner un fournisseur Affiliz
      </ModalHeader>
      <ModalBody style={{ height: '500px' }}>
        <Select
          value={availableAffilizSuppliers.find(
            (s: any) => s._id === selectedValue
          )}
          onChange={(option: any) => setSelectedValue(option?._id || '')}
          options={availableAffilizSuppliers}
          getOptionLabel={(option: any) => `${option.shopName} (${option._id})`}
          getOptionValue={(option: any) => option._id}
          placeholder="Sélectionner un fournisseur affiliz"
          isClearable
          className="react-select"
          classNamePrefix="react-select"
        />
      </ModalBody>
      <ModalFooter>
        <Button color="success" onClick={handleSave}>
          Enregistrer
        </Button>{' '}
        <Button color="secondary" onClick={onCancel}>
          Annuler
        </Button>
      </ModalFooter>
    </Modal>
  )
}
