import React, { useEffect, useState } from 'react'

// Redux
import { Link } from 'react-router-dom'
import {
  Row,
  Col,
  CardBody,
  Card,
  Container,
  Form,
  Input,
  Label,
  FormFeedback,
  Alert,
} from 'reactstrap'
// Formik validation
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useSelector, useDispatch } from 'react-redux'
// import images
import { loginuser, resetLoginMsgFlag } from 'slices/auth/login/thunk'
import withRouter from 'Components/Common/withRouter'
import { createSelector } from 'reselect'
import { useNavigate } from 'react-router-dom'
import { useQuery } from 'common/utils'
import { NavigationBar } from './NavigationBar'
import profile from '../../assets/images/profile-img.png'
import logo from '../../assets/images/logoR.png'
import lightlogo from '../../assets/images/logolg.png'
import { loginErrorMessage } from '../../common/errorMessages'
import { AuthFooter } from './AuthFooter'

const onMaintenance = process.env.REACT_APP_ON_MAINTENANCE === 'true'
// import thunk
const primaryColor = '#E57700'

const Login = () => {
  const navigate = useNavigate()
  const query = useQuery()
  const emailValidation = query.get('emailValidation')
  const [show, setShow] = useState(false)
  const [showConfirmationEmailBtn, setShowConfirmationEmailBtn] =
    useState(false)
  const dispatch: any = useDispatch()

  // meta title
  document.title = 'Login | ResellCorner'

  const selectProperties = createSelector(
    (state: any) => state.Login,
    (error) => error
  )

  const { error, state } = useSelector(selectProperties)

  // Form validation
  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required('Please Enter Your email'),
      password: Yup.string().required('Please Enter Your Password'),
    }),
    onSubmit: (values: any) => {
      dispatch(loginuser(values, navigate))
    },
  })

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        dispatch(resetLoginMsgFlag())
      }, 3000)
    }

    if (error == loginErrorMessage.EMAIL_NOT_VERIFIED) {
      setShowConfirmationEmailBtn(true)
    }
  }, [dispatch, error])

  const getNewConfirmationEmail = () => {
    // TODO: implement logic to send email here
  }

  const connexionButtonTitle = 'Connexion'
  const registerButtonTitle = 'Inscription'
  const connexionButtonLink = '/login'
  const registerButtonLink = '/register'
  return (
    <div
      style={{
        // import background color white
        backgroundColor: '#fff',
        flexDirection: 'column',
        justifyContent: 'space-between',
        display: 'flex',
        minHeight: '100vh',
      }}
    >
      <NavigationBar
        {...{
          registerButtonLink,
          registerButtonTitle,
        }}
      />
      {localStorage.getItem('disclaimer') && (
        <Alert
          style={{
            zIndex: 1000000,
            alignSelf: 'center',
            textAlign: 'center',
          }}
          color="danger"
        >
          <h1>{localStorage.getItem('disclaimer')}</h1>
        </Alert>
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 50,
          flex: 1,
        }}
      >
        <Row className="justify-content-center">
          <Col
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              flex: 2,
              backgroundColor: '#fff',
              padding: 20,
              borderRadius: 20,
              marginRight: 20,
              boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
            }}
          >
            <div className=" mt-4 text-center">
              <h3>Bienvenue sur ResellCorner</h3>
              <h4
                style={{
                  color: primaryColor,
                }}
              >
                Connexion
              </h4>
            </div>
            <Row className="overflow-hidden">
              <Row className="pt-0">
                <div className="p-2">
                  <Form
                    className="form-horizontal"
                    onSubmit={(e) => {
                      e.preventDefault()
                      validation.handleSubmit()
                      return false
                    }}
                  >
                    <div className="mb-3">
                      {error ? (
                        <Alert key="loginError" color="danger">
                          {error}
                        </Alert>
                      ) : null}
                      {emailValidation ? (
                        <Alert key="loginError" color="success">
                          Votre compte est validé avec succès. Veuillez vous
                          connecter pour continuer.
                        </Alert>
                      ) : null}
                      {showConfirmationEmailBtn ? (
                        <button
                          className="btn btn-outline-secondary btn-block mb-4"
                          type="button"
                          onClick={() => {}}
                        >
                          Recevoir un nouvel email de confirmation
                        </button>
                      ) : null}
                      {/* <Label className="form-label">Email</Label> */}
                      <Input
                        name="email"
                        className="form-control"
                        placeholder="Enter email"
                        type="text"
                        style={{
                          borderWidth: 0,
                          borderBottomWidth: 1,
                          borderRadius: 0,
                          height: 50,
                          marginBottom: 20,
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.email || ''}
                        invalid={
                          !!(
                            validation.touched.email && validation.errors.email
                          )
                        }
                      />
                      {validation.touched.email && validation.errors.email ? (
                        <FormFeedback type="invalid">
                          {validation.errors.email}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      {/* <Label className="form-label">Password</Label> */}
                      <div className="input-group auth-pass-inputgroup">
                        <Input
                          name="password"
                          value={validation.values.password || ''}
                          type={show ? 'text' : 'password'}
                          placeholder="Enter Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          style={{
                            borderWidth: 0,
                            borderBottomWidth: 1,
                            borderRadius: 0,
                            height: 50,
                          }}
                          invalid={
                            !!(
                              validation.touched.password &&
                              validation.errors.password
                            )
                          }
                        />
                        <button
                          onClick={() => setShow(!show)}
                          className="btn btn-light "
                          type="button"
                          id="password-addon"
                        >
                          <i className="mdi mdi-eye-outline" />
                        </button>
                      </div>
                      {validation.touched.password &&
                      validation.errors.password ? (
                        <FormFeedback type="invalid">
                          {validation.errors.password}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="customControlInline"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="customControlInline"
                      >
                        Se souvenir de moi
                      </label>
                    </div>

                    <div className="mt-3 d-grid">
                      <button
                        style={{
                          backgroundColor: primaryColor,
                          borderColor: primaryColor,
                          borderRadius: 30,
                        }}
                        className="btn btn-primary btn-block "
                        type="submit"
                      >
                        Connexion
                      </button>
                    </div>

                    <div className="mt-4 text-center">
                      <Link to="/forgot-password" className="text-muted">
                        <i className="mdi mdi-lock me-1" /> Mot de passe oublié
                        ?
                      </Link>
                    </div>
                  </Form>
                </div>
              </Row>
            </Row>
            <div className="mt-5 text-center">
              <p>
                Vous n'avez pas encore de compte ?{' '}
                <a
                  href="/register"
                  style={{
                    color: primaryColor,
                    textDecoration: 'underline !important',
                  }}
                >
                  {' '}
                  Inscription ICI{' '}
                </a>{' '}
              </p>
            </div>
          </Col>
          <Col
            className="d-none d-md-inline-block"
            style={{
              padding: 20,
              borderRadius: 10,
              backgroundColor: 'rgba(0, 0, 0, 0.3)',
              overflow: 'hidden',
              flex: 3,
              boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
            }}
          >
            <img
              style={{
                flex: 1,
                width: '100%',
              }}
              src={profile}
              alt="logo"
            />
          </Col>
        </Row>
      </div>
      <AuthFooter />
    </div>
  )
}

export default withRouter(Login)
