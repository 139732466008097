import { createGenericSlice } from 'reducers/genericSlice'
import { selectorsFactory } from 'reducers/factory/selector/selector'
import * as data from 'data'

export const reducerName = 'scans'
const scansSlice = createGenericSlice({
  name: reducerName,
})
const selectors = selectorsFactory(reducerName)
const { actions, reducer } = scansSlice
export { actions, reducer, selectors }

export const fetch = () => async (dispatch: any) => {
  dispatch(actions.fetchRequest())
  try {
    const res = await data.product.get('scans')

    dispatch(
      actions.fetchSuccess({
        data: res.data,
        loadMore: false,
      })
    )
  } catch (error) {
    dispatch(actions.fetchError())
  }
}

export const getOne = (id: string) => async (dispatch: any) => {
  dispatch(actions.editRequest())
  try {
    const res = await data.product.get(`scans/${id}`)
    dispatch(actions.editSuccess(res.data))
  } catch (error) {
    dispatch(actions.editError())
  }
}

export const deleteScan = (id: string) => async (dispatch: any) => {
  dispatch(actions.deleteRequest())
  try {
    await data.product.post(`scans/delete`, {
      fileId: id,
    })
    dispatch(actions.deleteSuccess(id))
  } catch (error) {
    dispatch(actions.deleteError())
  }
}
