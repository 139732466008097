import React from 'react'
import * as dataApi from 'data'
import { Button, ButtonGroup } from 'reactstrap'
import toastr from 'toastr'

const ScanActionButton = (params: any) => {
  const { data } = params
  const download = async (type: 'orignal' | 'processed') => {
    try {
      const content = await dataApi.product.post('scan/download', {
        fileId: data._id,
        type,
      })
      const blob = new Blob([content], { type: 'text/csv' })
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = `${new Date().getTime()}-exported_data.csv`
      a.click()
      window.URL.revokeObjectURL(url)
    } catch (error) {
      toastr.error(error as string, 'Error')
    }
  }
  const onDownloadOriginal = async () => {
    await download('orignal')
  }
  const onDownloadProcessed = async () => {
    await download('processed')
  }
  // const onDelete = () => {
  //   dispatch(Store.scans.deleteScan(data._id) as any)
  // }
  return (
    <ButtonGroup>
      <Button onClick={onDownloadOriginal}>
        <i className="fa fa-eye" /> Télécharger fichier original
      </Button>{' '}
      <Button color="primary" onClick={onDownloadProcessed}>
        <i className="fa fa-eye" /> Télécharger fichier traité
      </Button>
    </ButtonGroup>
  )
}
export { ScanActionButton }
