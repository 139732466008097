/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useMemo, useState } from 'react'
import {
  Alert,
  Button,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from 'reactstrap'
import * as data from 'data'
import { useSelector, useDispatch } from 'react-redux'
import * as Store from 'slices'
import toastr from 'toastr'
import { AgGridReact } from 'ag-grid-react'
import { ColDef } from '@ag-grid-community/core'
import { COUNTRY_FLAGS, COUNTRY_NAMES } from 'common/utils'
import { CopiableCell } from 'Components/CopiableCell/CopiableCell'
import { TSupplier } from 'data/SupplierEntity'
import moment from 'moment'
import { useQuery } from '@tanstack/react-query'
import { SupplierRow } from './SupplierRow'
import { AffilizSelector } from './AffilizSelector'

const CLASSNAMESTATUS = {
  ACTIVE: 'btn-rounded btn-outline-success',
  PROCESSING: 'btn-rounded btn-outline-info',
  INACTIVE: 'btn-rounded btn-outline-danger',
  WAITING: 'btn-rounded btn-outline-warning',
  FINISHED: 'btn-rounded btn-outline-secondary',
  ERROR: 'btn-rounded btn-outline-danger',
}
const ProcessingKeys = {
  lastOctoParseFetch: 'Dernière mise à jour Octoparse',
  lastUpdateDate: 'Dernière mise à jour',
  numberOfAddedProducts: 'Nombre de produits ajoutés',
  numberOfUpdatedProducts: 'Nombre de produits mis à jour',
  numberOfFailedEanProducts: 'Nombre de produits avec EAN/ASIN invalide',
  status: 'Statut',
  progress: 'Progression',
  total: 'Nombre total de produits chez Octoparse',
  offset: 'Produits scrapés',
  restTotal: 'Produits restants',
  processId: 'ID du processus',
}

const dateKeys = ['lastOctoParseFetch', 'lastUpdateDate']

const Supplier = () => {
  const [modal, setModal] = useState(false)
  const [processingData, setProcessingData] = useState<any>({})
  const [supplier, setSupplier] = useState<any>({})
  const [currentProcessData, setCurrentProcessData] = useState<any>({})
  const isUserAdmin = useSelector(Store.login.selectors.isAdmin)

  const { data: affilizData, isLoading } = useQuery<any>({
    queryKey: ['supplierAffiliz'],
    queryFn: () =>
      data.supplier.get('affiliz').then((res) =>
        res.data.reduce((acc, item) => {
          acc[item._id] = item
          return acc
        }, {})
      ),
  })

  const suppliers: TSupplier[] = useSelector(
    Store.suppliers.selectors.getAllCollection
  ) as any

  const dispatch = useDispatch()

  const pullData = (force = false) => {
    dispatch(Store.suppliers.fetchSuppliers(force) as any)
  }
  const forceRefresh = () => {
    pullData(true)
  }
  const isFetching = useSelector(Store.suppliers.selectors.isLoading)
  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      minWidth: 100,
      editable: true,
      resizable: true,
      height: 500,
      filter: true,
    }
  }, [])
  useEffect(() => {
    pullData(false)
    data.supplier
      .get('processing')
      .then((res) => {
        setProcessingData(res.data)
      })
      .catch((err) => {
        if (
          err === 'Invalid credentials' ||
          err === 'Wrong authentication token'
        ) {
          dispatch(Store.login.logoutUser() as any)
        }
        console.error(err)
      })
    dispatch(Store.suppliers.fetchSuppliers(false) as any)
  }, [])

  const refreshOctoparseData = async () => {
    const isOk = await dispatch(Store.suppliers.refreshOctoparseData() as any)
    if (isOk) {
      toastr.success('Les données ont été mises à jour', 'Fournisseurs')
    } else {
      toastr.error('Une erreur est survenue', 'Fournisseurs')
    }
  }

  const onClose = () => {
    // setIsEditing(false)
    setModal(false)
    setSupplier({})
    setCurrentProcessData({})
  }

  const valueSetter = (params, overwriteValue?: any) => {
    console.log('params', params.oldValue, params.newValue)

    if (params.oldValue === params.newValue) {
      return false
    }
    const name = params.colDef.field
    const id = params.data._id
    const values = overwriteValue || params.newValue
    console.log({
      id,
      name,
      values,
    })

    return data.supplier
      .put(id, { [name]: values })
      .then((res) => {
        console.log('res', res)
        toastr.success('La mise à jour a été effectuée', name.toUpperCase())
        dispatch(Store.suppliers.fetchById(id) as any)
        return true
      })
      .catch((err) => {
        toastr.error('Erreur pendant la mise à jour ', name.toUpperCase())
        console.log('err', err)
        return false
      })
  }

  const fields = [
    {
      field: '_id',
      headerName: 'id',
      cellRenderer: (params) => (<CopiableCell {...params} />) as any,
      hide: !isUserAdmin,
      editable: false,
    },
    {
      field: 'taskId',
      headerName: 'TaskId',
      cellRenderer: (params) => (<CopiableCell {...params} />) as any,
      hide: !isUserAdmin,
      valueSetter,
    },
    {
      field: 'affilizId',
      headerName: 'Affiliz ID',
      hide: !isUserAdmin,
      editable: false,
      valueSetter,
      cellRenderer: (params) => {
        const [isEditing, setIsEditing] = useState(false)

        if (isEditing) {
          return (
            <AffilizSelector
              value={params.data.affilizId}
              supplierId={params.data._id}
              onCancel={() => setIsEditing(false)}
              onSuccess={() => {
                setIsEditing(false)
                dispatch(Store.suppliers.fetchById(params.data._id) as any)
              }}
            />
          )
        }

        const affilizSupplier = affilizData?.[params.data.affilizId]
        return (
          <div onDoubleClick={() => setIsEditing(true)}>
            {affilizSupplier
              ? `${affilizSupplier.shopName} (${params.data.affilizId})`
              : 'NOT SET YET'}
          </div>
        )
      },
    },
    {
      field: 'name',
      headerName: 'Nom',
      valueSetter,
    },
    {
      field: 'country',
      headerName: 'Pays',
      valueFormatter: (params: any) => {
        return `${COUNTRY_FLAGS[params.value]} ${COUNTRY_NAMES[params.value]}`
      },
      valueSetter,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: ['FR', 'DE', 'IT', 'ES', 'UK', 'ES'],
      },
    },
    {
      field: 'type',
      headerName: 'Type',
      valueFormatter: (params: any) => {
        return params.value === 'supplier'
          ? 'Site Marchand'
          : 'Site professionnel'
      },
      valueSetter,
    },
    {
      field: 'numberOfProducts',
      headerName: 'Nombre de produits',
      editable: false,
    },
    {
      field: 'status',
      headerName: 'Status',
      valueSetter,
      hide: !isUserAdmin,
      cellEditor: 'agSelectCellEditor',
      cellRenderer: (params) => {
        const toggle = async () => {
          setSupplier(params.data)
          setModal(true)
          setCurrentProcessData(JSON.parse(processingData[params.data._id]))
        }
        const _processingData = JSON.parse(
          processingData[params.data._id] || '{}'
        )
        const supplier = params.data

        return (
          <button
            type="button"
            disabled={!_processingData}
            onClick={toggle}
            className={`btn ${
              CLASSNAMESTATUS[_processingData?.status || supplier.status] || ''
            }`}
          >
            {supplier.status === 'PROCESSING' ||
              (_processingData?.status === 'PROCESSING' && (
                <i className="bx bx-loader bx-spin font-size-16 'align-middle me-2" />
              ))}
            {_processingData?.status === 'FINISHED'
              ? supplier.status
              : _processingData?.status
              ? _processingData.status
              : supplier.status}{' '}
            {!_processingData ? '' : '*'}
          </button>
        )
      },
      cellEditorParams: {
        values: [
          'ACTIVE',
          'PROCESSING',
          'INACTIVE',
          'WAITING',
          'FINISHED',
          'ERROR',
        ],
      },
    },
    {
      field: 'octoParseStatus',
      headerName: 'Octoparse Status',
      hide: !isUserAdmin,
      editable: false,
    },
    {
      field: 'lastUpdateDate',
      headerName: 'Derniere mise à jour',
      editable: false,
      valueFormatter: (params: any) => {
        if (params.data._id === '65bd5600bc6de92a651f048b') {
          return moment().format('YYYY-MM-DD')
        }
        return moment(params.value).format('YYYY-MM-DD')
      },
    },
    {
      field: 'lastOctoParseStatusUpdate',
      headerName: 'Last Octoparse Update',
      hide: !isUserAdmin,
      editable: false,
    },
    {
      field: 'outDateInterval',
      hide: !isUserAdmin,
      headerName: 'Interval de cleaning',
      valueSetter,
    },
    {
      field: 'hasAffiliation',
      headerName: 'Fournisseur affilié',
      valueFormatter: (params: any) => (params.value === true ? 'Oui' : 'Non'),
      cellEditor: 'agCheckboxCellEditor',
      valueSetter,
      hide: !isUserAdmin,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      editable: false,
      hide: !isUserAdmin,
      cellRenderer: (params) => {
        const supplierId = params.data._id.toString()
        const supplier = params.data
        const activeSupplier = async () => {
          if (
            supplier.octoParseStatus === 'READY_TO_PROCESS' &&
            supplier.status !== 'ACTIVE'
          ) {
            console.log('activeSupplier', supplierId)
            await dispatch(Store.suppliers.activeSupplier(supplierId) as any)
            return
          }

          toastr.error('Supplier is not ready to process')
          // await dispatch(Store.suppliers.activeSupplier(supplierId) as any)
        }
        const activateOrDeactivateSupplier = async () => {
          await dispatch(
            Store.suppliers.editSupplier(supplierId, {
              isActive: !supplier.isActive,
            }) as any
          )
          dispatch(Store.suppliers.fetchSuppliers(true) as any)
        }
        return (
          <div>
            <button
              type="button"
              disabled={
                supplier.octoParseStatus !== 'READY_TO_PROCESS' ||
                supplier.status === 'ACTIVE'
              }
              className="btn btn-warning btn-rounded"
              onClick={activeSupplier}
            >
              SEND TO PROCESS
            </button>
            <button
              type="button"
              className={`btn ${
                supplier.isActive ? 'btn-danger' : 'btn-success'
              }`}
              onClick={activateOrDeactivateSupplier}
            >
              {supplier.isActive ? 'Désactiver' : 'Activer'}
            </button>
          </div>
        )
      },
    },
  ]

  return (
    <div className="page-content" style={{ height: '100vh' }}>
      {isUserAdmin && (
        <Modal isOpen={modal} toggle={onClose} backdrop>
          <ModalHeader toggle={onClose}>{supplier.name}</ModalHeader>
          <ModalBody>
            <Row>
              <Table>
                {Object.keys(ProcessingKeys).map((key) =>
                  currentProcessData?.[key] ? (
                    <tr key={key}>
                      <td>
                        <strong>{ProcessingKeys[key]}</strong>
                      </td>
                      <td>
                        {dateKeys.includes(key)
                          ? moment(currentProcessData?.[key]).format(
                              'YYYY-MM-DD HH:mm'
                            )
                          : currentProcessData?.[key]}
                      </td>
                    </tr>
                  ) : null
                )}
              </Table>
            </Row>
            <Row>
              {currentProcessData?.errors?.length > 0
                ? currentProcessData?.errors.map((error) => (
                    <Col key={error} xs={12}>
                      <br />
                      {error}
                    </Col>
                  ))
                : null}
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={onClose}>
              Fermer
            </Button>
          </ModalFooter>
        </Modal>
      )}
      {localStorage.getItem('disclaimer') && (
        <Alert
          style={{
            zIndex: 1000000,
            alignSelf: 'center',
            textAlign: 'center',
          }}
          color="danger"
        >
          <h1>{localStorage.getItem('disclaimer')}</h1>
        </Alert>
      )}
      <h1>Supplier</h1>
      {isUserAdmin && (
        <div className="hstack gap-3 p-3">
          <button type="button" className="btn btn-primary">
            Ajouter un supplier
          </button>
          <div className="vr" />
          <button
            type="button"
            disabled={isFetching}
            onClick={refreshOctoparseData}
            className="btn btn-outline-info"
          >
            {isFetching && (
              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2" />
            )}{' '}
            Refresh Octoparse
          </button>
          <div className="vr" />
          <button
            type="button"
            onClick={forceRefresh}
            className="btn btn-outline-info"
          >
            Force Refresh
          </button>
        </div>
      )}
      <div
        className="ag-theme-quartz" // applying the Data Grid theme
        style={{ height: '85%' }} // the Data Grid will fill the size of the parent container
      >
        <AgGridReact<any>
          rowData={[...suppliers]}
          columnDefs={fields}
          defaultColDef={defaultColDef as any}
          pagination
          paginationPageSize={50}
          // onCellClicked={(e) => {
          //   console.log('e', e)

          //   if (e.colDef.field === 'status' && processingData[e.data?._id]) {
          //     setSupplier(e.data)
          //     setModal(true)
          //     setCurrentProcessData(JSON.parse(processingData[e.data?._id]))
          //   }
          // }}
        />
      </div>
    </div>
  )
}

export { Supplier }
