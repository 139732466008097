import React from 'react'
import { Container, Row, Col } from 'reactstrap'
// import { FaYoutube, FaTiktok } from 'react-icons/fa' // Social Media Icons
import logo from '../../assets/images/logoR.png'
import lightlogo from '../../assets/images/logolg.png'

const AuthFooter = () => {
  return (
    <footer
      style={{
        backgroundColor: '#1C2237',
        padding: '40px 0',
        color: '#FFFFFF',
        borderTopRightRadius: '30px',
        borderTopLeftRadius: '30px',
      }}
    >
      <Container>
        <Row>
          {/* Logo and Social Media Section */}
          <Col md="3" className="text-center">
            <img
              src={lightlogo} // Replace with your logo path
              alt="Resell Corner Logo"
              style={{ height: '40px', marginBottom: '10px' }}
            />
            {/* <h5 style={{ color: '#FF9900' }}>MÉDIAS SOCIAUX</h5> */}
            {/* <div>
              <FaYoutube
                size={30}
                style={{ marginRight: '15px', color: '#FFFFFF' }}
              />
              <FaTiktok size={30} style={{ color: '#FFFFFF' }} />
            </div> */}
          </Col>

          {/* Pages Section */}
          {/* <Col md="3" className="text-center">
            <h5 style={{ color: '#FF9900' }}>PAGES</h5>
            <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
              <li>
                <a
                  href="#"
                  style={{ color: '#FFFFFF', textDecoration: 'none' }}
                >
                  Fonctionnalités
                </a>
              </li>
              <li>
                <a
                  href="#"
                  style={{ color: '#FFFFFF', textDecoration: 'none' }}
                >
                  Prix
                </a>
              </li>
              <li>
                <a
                  href="#"
                  style={{ color: '#FFFFFF', textDecoration: 'none' }}
                >
                  Contact
                </a>
              </li>
              <li>
                <a
                  href="#"
                  style={{ color: '#FFFFFF', textDecoration: 'none' }}
                >
                  Tutoriels
                </a>
              </li>
            </ul>
          </Col> */}
          <Col md="3" />

          {/* Useful Links Section */}
          <Col md="3" className="text-center">
            <h5 style={{ color: '#FF9900' }}>LIENS UTILES</h5>
            <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
              <li>
                <a
                  href="https://www.resellcorner.io/faq"
                  style={{ color: '#FFFFFF', textDecoration: 'none' }}
                >
                  Faq
                </a>
              </li>
              <li>
                <a
                  href="https://www.resellcorner.io/cgu"
                  style={{ color: '#FFFFFF', textDecoration: 'none' }}
                >
                  CGV/CGU
                </a>
              </li>
              <li>
                <a
                  href="https://www.resellcorner.io/legal"
                  style={{ color: '#FFFFFF', textDecoration: 'none' }}
                >
                  Mentions légales
                </a>
              </li>
              <li>
                <a
                  href="https://www.resellcorner.io/confidentials"
                  style={{ color: '#FFFFFF', textDecoration: 'none' }}
                >
                  Politique de confidentialité
                </a>
              </li>
            </ul>
          </Col>

          {/* Contact Section */}
          <Col md="3" className="text-center">
            <h5 style={{ color: '#FF9900' }}>CONTACT</h5>
            <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
              <li>Lundi - Vendredi</li>
              <li>8h30 - 18h30</li>
              <li>
                <a
                  href="mailto:contact@resellcorner.io"
                  style={{ color: '#FFFFFF', textDecoration: 'none' }}
                >
                  contact@resellcorner.io
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

export { AuthFooter }
