export const getSearchConditionMongoose = (values: any) => {
  if (!values) {
    return {}
  }
  if (Object.keys(values).length === 0) {
    return {}
  }
  let searchConditions: any = {}
  if (values.profitUnitMin) {
    searchConditions = {
      ...searchConditions,
      profit: {
        $gte: Number(values.profitUnitMin),
      },
    }
  }
  if (values.profitUnitMax) {
    searchConditions = {
      ...searchConditions,
      profit: {
        ...(searchConditions.profit || {}),
        $lte: Number(values.profitUnitMax),
      },
    }
  }
  if (values.profitMonthMin) {
    searchConditions = {
      ...searchConditions,
      profitPerMonth: {
        $gte: Number(values.profitMonthMin),
      },
    }
  }
  if (values.profitMonthMax) {
    searchConditions = {
      ...searchConditions,
      profitPerMonth: {
        ...(searchConditions.profitPerMonth || {}),
        $lte: Number(values.profitMonthMax),
      },
    }
  }
  if (values.amazonNotSeller === true) {
    searchConditions = {
      ...searchConditions,
      sellerType: {
        $ne: 'AMZ',
      },
    }
  }
  if (values.isGrouped === true) {
    searchConditions = {
      ...searchConditions,
      isGrouped: false,
    }
  }
  if (values.suppliers) {
    searchConditions = {
      ...searchConditions,
      supplier: {
        $in: values.suppliers.map((item) => item.value),
      },
    }
  }
  if (values.marketplace) {
    searchConditions = {
      ...searchConditions,
      amazonCountry: {
        $in: [values.marketplace],
      },
    }
  }
  if (values.supplierCountry) {
    searchConditions = {
      ...searchConditions,
      supplierCountry: {
        $in: [values.supplierCountry],
      },
    }
  }
  if (values.supplierExcludedEanAsinList?.length > 0) {
    searchConditions = {
      ...searchConditions,
      ean: {
        $nin: values.supplierExcludedEanAsinList,
      },
      upc: {
        $nin: values.supplierExcludedEanAsinList,
      },
    }
  }
  if (values.supplierIncludedEanAsinList?.length > 0) {
    searchConditions = {
      ...searchConditions,
      $or: [
        { ean: { $in: values.supplierIncludedEanAsinList } },
        { upc: { $in: values.supplierIncludedEanAsinList } },
      ],
    }
  }
  if (values.includedKeywordList?.length > 0) {
    const regex = values.includedKeywordList.join('|')
    searchConditions = {
      ...searchConditions,
      title: {
        $regex: regex,
        $options: 'i',
      },
    }
  }
  if (values.excludedKeywordList?.length > 0) {
    const regex = values.excludedKeywordList.join('|')
    searchConditions = {
      ...searchConditions,
      title: {
        ...(searchConditions.title || {}),
        $not: {
          $regex: regex,
          $options: 'i',
        },
      },
    }
  }
  if (values.includedEanAsinList?.length > 0) {
    searchConditions = {
      ...searchConditions,

      $or: [
        { asin: { $in: values.includedEanAsinList } },
        { ean: { $in: values.includedEanAsinList } },
      ],
    }
  }
  if (values.excludedEanAsinList?.length > 0) {
    searchConditions = {
      ...searchConditions,
      asin: {
        ...(searchConditions.asin || {}),
        $nin: values.excludedEanAsinList,
      },
    }
  }
  if (values.priceAmazonMin) {
    searchConditions = {
      ...searchConditions,
      amazonPrice: {
        $gte: Number(values.priceAmazonMin),
      },
    }
  }
  if (values.priceAmazonMax) {
    searchConditions = {
      ...searchConditions,
      amazonPrice: {
        ...(searchConditions.amazonPrice || {}),
        $lte: Number(values.priceAmazonMax),
      },
    }
  }
  if (values.roiUnitMin) {
    searchConditions = {
      ...searchConditions,
      roi: {
        $gte: Number(values.roiUnitMin),
      },
    }
  }
  if (values.roiUnitMax) {
    searchConditions = {
      ...searchConditions,
      roi: {
        ...(searchConditions.roi || {}),
        $lte: Number(values.roiUnitMax),
      },
    }
  }
  if (values.salesPerMonthMin) {
    searchConditions = {
      ...searchConditions,
      estimatedSales: {
        $gte: Number(values.salesPerMonthMin),
      },
    }
  }
  if (values.salesPerMonthMax) {
    searchConditions = {
      ...searchConditions,
      estimatedSales: {
        ...(searchConditions.estimatedSales || {}),
        $lte: Number(values.salesPerMonthMax),
      },
    }
  }
  if (values.supplierPriceMin) {
    searchConditions = {
      ...searchConditions,
      supplierPrice: {
        $gte: Number(values.supplierPriceMin),
      },
    }
  }
  if (values.supplierPriceMax) {
    searchConditions = {
      ...searchConditions,
      supplierPrice: {
        ...(searchConditions.supplierPrice || {}),
        $lte: Number(values.supplierPriceMax),
      },
    }
  }

  return searchConditions
}
