import { combineReducers } from 'redux'

// Front
import LayoutReducer from './layouts/reducer'
import * as LoginFullReducer from './auth/login/reducer'
import LoginReducer from './auth/login/reducer'
import ProfileReducer from './auth/profile/reducer'
import ForgotPasswordReducer from './auth/forgetpwd/reducer'
import AccountReducer from './auth/register/reducer'
import * as user from './users'
import * as products from './products/products'
import * as loginThunk from './auth/login/thunk'
import * as prices from './prices'
import * as subscriptions from './subscriptions'
import * as invoices from './invoices'
import * as suppliers from './suppliers'
import * as scans from './scans/scans'

const rootReducer = combineReducers({
  Layout: LayoutReducer,
  Login: LoginReducer,
  Profile: ProfileReducer,
  ForgetPassword: ForgotPasswordReducer,
  Account: AccountReducer,
  [user.reducerName]: user.reducer,
  [products.reducerName]: products.reducer,
  [prices.reducerName]: prices.reducer,
  [subscriptions.reducerName]: subscriptions.reducer,
  [invoices.reducerName]: invoices.reducer,
  [suppliers.reducerName]: suppliers.reducer,
  [scans.reducerName]: scans.reducer,
})

const login = {
  ...LoginFullReducer,
  ...loginThunk,
}
export type RootState = ReturnType<typeof rootReducer>
export {
  user,
  login,
  products,
  prices,
  subscriptions,
  invoices,
  suppliers,
  scans,
}
export default rootReducer
