/* eslint-disable camelcase */
import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Button, Row } from 'reactstrap'
import * as data from 'data'
import { useSelector, useDispatch } from 'react-redux'
import * as Store from 'slices'
import toastr from 'toastr'

const AmazonSellerConnectButton = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const user: any = useSelector(Store.login.selectors.user)
  const sendCodeToBackend = async (
    oauthCode: string,
    sellerPartnerId: string
  ) => {
    try {
      const response = await data.user.post('sellerapi', {
        spapi_oauth_code: oauthCode,
        selling_partner_id: sellerPartnerId,
      })

      // if (response.status === 200) {
      //   toastr.success(
      //     'Amazon Seller account connected successfully!',
      //     'Success'
      //   )

      //   // history.push('/dashboard') // Redirect to dashboard after successful connection
      // }
    } catch (error: any) {
      console.log(error.message)

      toastr.error('Error connecting to Amazon Seller', 'Error')
      // alert('Failed to connect to Amazon Seller account.')
    }
  }

  useEffect(() => {
    dispatch(Store.login.fetchCurrentUser() as any)
  }, [])

  // Step 2: Capture the code and selling_partner_id after redirect
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const spapi_oauth_code = searchParams.get('spapi_oauth_code')
    const selling_partner_id = searchParams.get('selling_partner_id')

    // If we have both parameters, send them to the backend
    if (spapi_oauth_code && selling_partner_id) {
      sendCodeToBackend(spapi_oauth_code, selling_partner_id)
    }
  }, [location.search])

  const handleConnect = () => {
    // const clientId = 'YOUR_CLIENT_ID' // Remplacez par votre ID client SP-API
    const redirectUri = `${window.location.protocol}//${window.location.host}${window.location.pathname}` // URL de redirection sur votre serveur
    const sellingPartnerId = process.env.REACT_APP_LWA_CLIENT_ID // ID de votre app
    const authUrl = `https://sellercentral.amazon.com/apps/authorize/consent?application_id=${sellingPartnerId}&state=login&redirect_uri=${encodeURIComponent(
      redirectUri
    )}`

    // Rediriger vers Amazon pour l'authentification
    window.location.href = authUrl
  }

  return (
    <Row style={{ margin: 20, maxWidth: 300 }}>
      {user?.isSellerApiConnected === false ? (
        <Button
          onClick={handleConnect}
          style={{
            backgroundColor: '#FF9900',
            borderColor: '#FF9900',
            color: '#000000',
          }}
          className="btn btn-primary"
        >
          <img
            alt="Amazon Seller"
            width="20px"
            height="20px"
            src="https://upload.wikimedia.org/wikipedia/commons/4/4a/Amazon_icon.svg"
          />{' '}
          Connecter votre Amazon Seller
        </Button>
      ) : (
        <Button disabled outline color="success">
          Vous êtes connecté à Amazon Seller
        </Button>
      )}
    </Row>
  )
}

export { AmazonSellerConnectButton }
