import React from 'react'
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap'

type PageItem = number | string

const getPaginationArray = (
  currentPage: number,
  totalPages: number
): PageItem[] => {
  const delta = 2 // Number of pages to show before and after current page
  const range: number[] = []
  const rangeWithDots: PageItem[] = []

  // Always include first page
  range.push(1)

  for (let i = currentPage - delta; i <= currentPage + delta; i++) {
    if (i > 1 && i < totalPages) {
      range.push(i)
    }
  }

  // Always include last page
  if (totalPages > 1) {
    range.push(totalPages)
  }

  // Add dots between numbers if there are gaps
  let l: number | undefined
  for (const i of range) {
    if (l) {
      if (i - l === 2) {
        // If there's just one number between, show it
        rangeWithDots.push(l + 1)
      } else if (i - l !== 1) {
        // If there's more than one number between, show dots
        rangeWithDots.push('...')
      }
    }
    rangeWithDots.push(i)
    l = i
  }

  return rangeWithDots
}

interface CustomPaginationProps {
  hastNextPage: boolean
  currentPage: number
  hasPrevPage: boolean
  handlePageClick: (e: React.MouseEvent, page: number) => void
}

const CustomPagination: React.FC<CustomPaginationProps> = ({
  hastNextPage,
  currentPage,
  hasPrevPage,
  handlePageClick,
}) => {
  let totalPages = 0
  if (hasPrevPage && hastNextPage) {
    totalPages = currentPage + 2
  } else if (hasPrevPage || hastNextPage) {
    totalPages = currentPage + 1
  } else {
    totalPages = currentPage
  }

  const pages = getPaginationArray(currentPage, totalPages)

  return (
    <Pagination>
      {/* First Page */}
      <PaginationItem disabled={currentPage <= 1}>
        <PaginationLink
          onClick={(e) => handlePageClick(e, 1)}
          first
          href="#"
          style={{ cursor: 'pointer', fontSize: '20px' }}
        />
      </PaginationItem>

      {/* Previous Page */}
      <PaginationItem disabled={!hasPrevPage}>
        <PaginationLink
          onClick={(e) => handlePageClick(e, currentPage - 1)}
          previous
          href="#"
          style={{ cursor: 'pointer', fontSize: '20px' }}
        />
      </PaginationItem>

      {/* Page Numbers */}
      {pages.map((page, index) => {
        if (page === '...') {
          return (
            <PaginationItem disabled key={`dots-${index}`}>
              <PaginationLink
                style={{ cursor: 'default', fontSize: '20px' }}
                href="#"
              >
                {page}
              </PaginationLink>
            </PaginationItem>
          )
        }
        return (
          <PaginationItem active={page === currentPage} key={page}>
            <PaginationLink
              style={{ cursor: 'pointer', fontSize: '20px' }}
              onClick={(e) => handlePageClick(e, page as number)}
              href="#"
            >
              {page}
            </PaginationLink>
          </PaginationItem>
        )
      })}

      {/* Next Page */}
      <PaginationItem disabled={!hastNextPage}>
        <PaginationLink
          onClick={(e) => handlePageClick(e, currentPage + 1)}
          next
          href="#"
          style={{ cursor: 'pointer', fontSize: '20px' }}
        />
      </PaginationItem>

      {/* Last Page */}
      <PaginationItem disabled={currentPage >= totalPages}>
        <PaginationLink
          onClick={(e) => handlePageClick(e, totalPages)}
          last
          href="#"
          style={{ cursor: 'pointer', fontSize: '20px' }}
        />
      </PaginationItem>
    </Pagination>
  )
}

export { CustomPagination }
