import React from 'react'
import { Container, CardBody, Row } from 'reactstrap'
import { useSelector, useDispatch } from 'react-redux'
import * as R from 'ramda'
import toastr from 'toastr'

// Components
import { Filters } from 'Components/Filters/Filters'
import { ResultTable } from 'Components/ResultTable/ResultTable'
import { CustomPagination } from 'Components/CustomPagination/CustomPagination'

// Services & Utils
import * as data from 'data'
import * as Store from 'slices'
import { getSearchConditionMongoose } from './searchHelper'

// Types
interface SearchResult {
  docs: Array<any>
  meta: {
    hasNextPage: boolean
    hasPrevPage: boolean
    page: number
  }
}

interface SearchConditions {
  suppliers?: string[]
  [key: string]: any
}

// Custom Hook
const useDashboardLogic = () => {
  const resultRef = React.useRef<any>()
  const [loading, setLoading] = React.useState(false)
  const [result, setResult] = React.useState<SearchResult>({} as SearchResult)
  const [conditions, setConditions] = React.useState<SearchConditions>({})

  const dispatch = useDispatch()
  const user: any = useSelector(Store.login.selectors.user)
  const suppliers = useSelector(Store.suppliers.selectors.getAllCollection)
  const supplerIndexByType = R.groupBy(R.prop('type') as any, suppliers)

  React.useEffect(() => {
    dispatch(Store.login.fetchCurrentUser() as any)
  }, [user?._id])

  React.useEffect(() => {
    dispatch(Store.suppliers.fetchSuppliers() as any)
  }, [])

  return {
    resultRef,
    loading,
    setLoading,
    result,
    setResult,
    conditions,
    setConditions,
    supplerIndexByType,
    dispatch,
  }
}

const Dashboard: React.FC = () => {
  document.title = 'Dashboards | ResellCorner'
  const {
    resultRef,
    loading,
    setLoading,
    result,
    setResult,
    conditions,
    setConditions,
    dispatch,
  } = useDashboardLogic()

  const handleSearchError = (err: any) => {
    if (['Invalid credentials', 'Wrong authentication token'].includes(err)) {
      dispatch(Store.login.logoutUser() as any)
    } else {
      toastr.error('Une erreur est survenue lors de la recherche. Réessayez.')
    }
    setResult({} as SearchResult)
  }

  const paginateSearch = async (
    page: number,
    poConditions?: SearchConditions
  ) => {
    if (!conditions && !poConditions) return
    setLoading(true)
    try {
      const res = await data.product.findByConditions('search', {
        conditions: poConditions || conditions,
        paginationOption: { page },
        group: {
          _id: '$asin',
          count: { $first: '$count' }, // Assuming you want to keep some count
          profit: { $first: '$profit' }, // Preserve the first profit value
          lastUpdateDate: { $first: '$lastUpdateDate' }, // Preserve the first last update date
        },
        sort: {
          profit: -1,
          lastUpdateDate: -1,
        },
      })
      setResult(res.data)
    } catch (err: any) {
      handleSearchError(err)
    } finally {
      setLoading(false)
    }
  }

  const onSearch = (values: SearchConditions) => {
    if (!values || Object.keys(values).length === 0) return

    if (!values.suppliers?.length) {
      toastr.error("⚠️ Merci d'ajouter au moins 1 fournisseur à la recherche")
      return
    }

    const searchConditions = getSearchConditionMongoose(values)
    setConditions(searchConditions)
    paginateSearch(1, searchConditions)
  }

  const handleCSVExport = async () => {
    try {
      const content = await data.product.post('download', conditions)
      const blob = new Blob([content], { type: 'text/csv' })
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = `${new Date().getTime()}-exported_data.csv`
      a.click()
      window.URL.revokeObjectURL(url)
    } catch (error) {
      console.error('CSV Export failed:', error)
    }
  }

  return (
    <div className="page-content">
      <div ref={resultRef}>
        <Filters onCSVParse={handleCSVExport} onSubmit={onSearch} />
        <Row className="mt-20">
          <CustomPagination
            hastNextPage={result?.meta?.hasNextPage}
            currentPage={result?.meta?.page || 1}
            hasPrevPage={result?.meta?.hasPrevPage}
            handlePageClick={(_, i) => paginateSearch(i)}
          />
        </Row>
      </div>
      <Container fluid>
        <CardBody>
          <ResultTable
            allowNonProfit
            isLoading={loading}
            tableResult={result?.docs || []}
          />
          <Row>
            <CustomPagination
              hastNextPage={result?.meta?.hasNextPage}
              currentPage={result?.meta?.page || 1}
              hasPrevPage={result?.meta?.hasPrevPage}
              handlePageClick={(_, i) => paginateSearch(i)}
            />
          </Row>
        </CardBody>
      </Container>
    </div>
  )
}

export default Dashboard
