// tokenService.js
export const setToken = (token) => localStorage.setItem('authToken', token)
export const getToken = () => {
  const oldToken = localStorage.getItem('token')
  const newToken = localStorage.getItem('authToken')
  if (oldToken) {
    localStorage.removeItem('token')
  }
  if (!newToken) {
    setToken(oldToken)
  }
  return newToken || oldToken
}

export const setAdminToken = (token) =>
  localStorage.setItem('adminToken', token)
export const getAdminToken = () => localStorage.getItem('adminToken')

export const switchToUserToken = (userToken) => {
  // Save the admin token if not already saved
  if (!getAdminToken()) {
    setAdminToken(getToken())
  }
  setToken(userToken)
}

export const switchBackToAdminToken = () => {
  const adminToken = getAdminToken()
  if (adminToken) {
    setToken(adminToken)
    localStorage.removeItem('adminToken') // Clear it after switching back
  }
}
