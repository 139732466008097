import React, { useEffect } from 'react'
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Input,
  Label,
  Form,
  FormFeedback,
} from 'reactstrap'

// Formik Validation
import * as Yup from 'yup'
import { useFormik } from 'formik'

// action
import { useSelector, useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { createSelector } from 'reselect'
import firebase from 'firebase/compat/app'
import { registerUser, apiError } from '../../slices/thunk'
import profile from '../../assets/images/profile-img.png'
// Add the Firebase products that you want to use
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
// redux

// import images
import profileImg from '../../assets/images/profile-img.png'
import logoImg from '../../assets/images/logoR.png'
import { NavigationBar } from './NavigationBar'
import { AuthFooter } from './AuthFooter'

const primaryColor = '#E57700'

const InputStyle = {
  borderWidth: 0,
  borderBottomWidth: 1,
  borderRadius: 0,
  height: 50,
  marginBottom: 20,
}

const Register: React.FC<any> = () => {
  // meta title
  document.title = 'Register | ResellCorner'

  const navigate = useNavigate()

  const dispatch = useDispatch<any>()

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
      firstName: '',
      lastName: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .lowercase("L'adresse email doit être en minuscule")
        .required("L'adresse email est obligatoire"),
      firstName: Yup.string().required('Veuillez entrer votre prénom'),
      lastName: Yup.string().required('Veuillez entrer votre nom'),
      password: Yup.string().required('Veuillez entrer votre mot de passe'),
    }),
    onSubmit: (values) => {
      // alert("La création de compte inactif pour l'instant")
      try {
        dispatch(registerUser(values, navigate))
      } catch (error) {
        console.log('error', error)
      }
    },
  })

  const selectProperties = createSelector(
    (state: any) => {
      return state.Account
    },
    (account) => {
      return {
        user: account.user,
        registrationError: account.registrationError,
        loading: account.loading,
        redirect: account.redirect,
      }
    }
  )

  const { user, redirect, registrationError } = useSelector(selectProperties)

  useEffect(() => {
    dispatch(apiError())
  }, [dispatch])

  useEffect(() => {
    if (registrationError) {
      setTimeout(() => {
        dispatch(apiError())
      }, 2000)
    }
  }, [registrationError])
  const connexionButtonTitle = 'Connexion'
  const connexionButtonLink = '/login'
  return (
    <div
      style={{
        // import background color white
        backgroundColor: '#fff',
        flexDirection: 'column',
        justifyContent: 'space-between',
        display: 'flex',
        minHeight: '100vh',
      }}
    >
      <NavigationBar
        {...{
          connexionButtonTitle,
          connexionButtonLink,
        }}
      />
      {localStorage.getItem('disclaimer') && (
        <Alert
          style={{
            zIndex: 1000000,
            alignSelf: 'center',
            textAlign: 'center',
          }}
          color="danger"
        >
          <h1>{localStorage.getItem('disclaimer')}</h1>
        </Alert>
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 50,
          flex: 1,
        }}
      >
        <Row className="justify-content-center">
          <Col
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              flex: 2,
              backgroundColor: '#fff',
              padding: 20,
              borderRadius: 20,
              marginRight: 20,
              boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
            }}
          >
            <div className="mt-4 text-center">
              <h3>Inscription Plateforme</h3>
              <h3
                style={{
                  color: primaryColor,
                }}
              >
                ResellCorner
              </h3>
            </div>
            <div className="p-2">
              <Form
                className="form-horizontal"
                onSubmit={(e) => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                {user && user ? (
                  <Alert color="success">
                    Votre inscription est bien prise en compte ! Vous allez
                    recevoir un email de confirmation.
                  </Alert>
                ) : null}

                {registrationError && registrationError ? (
                  <Alert color="danger">{registrationError}</Alert>
                ) : null}

                {Object.keys(validation.errors).length > 0
                  ? (Object.values(validation.errors) || [])?.map(
                      (error: any) => (
                        <Alert key={error} color="danger">
                          {error}
                        </Alert>
                      )
                    )
                  : null}

                <div className="mb-3">
                  <Label className="form-label">Prénom</Label>
                  <Input
                    name="firstName"
                    type="text"
                    style={InputStyle}
                    placeholder="Entrer votre prénom"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.firstName || ''}
                    invalid={
                      !!(
                        validation.touched.firstName &&
                        validation.errors.firstName
                      )
                    }
                  />
                  {validation.touched.firstName &&
                  validation.errors.firstName ? (
                    <FormFeedback type="invalid">
                      {validation.errors.firstName}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="mb-3">
                  <Label className="form-label">Nom</Label>
                  <Input
                    name="lastName"
                    type="text"
                    style={InputStyle}
                    placeholder="Entrer votre nom"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.lastName || ''}
                    invalid={
                      !!(
                        validation.touched.lastName &&
                        validation.errors.lastName
                      )
                    }
                  />
                  {validation.touched.lastName && validation.errors.lastName ? (
                    <FormFeedback type="invalid">
                      {validation.errors.lastName}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="mb-3">
                  <Label className="form-label">Email</Label>
                  <Input
                    id="email"
                    name="email"
                    style={InputStyle}
                    className="form-control"
                    placeholder="Entrer votre email"
                    type="email"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.email || ''}
                    invalid={
                      !!(validation.touched.email && validation.errors.email)
                    }
                  />
                  {validation.touched.email && validation.errors.email ? (
                    <FormFeedback type="invalid">
                      {validation.errors.email}
                    </FormFeedback>
                  ) : null}
                </div>
                <div className="mb-3">
                  <Label className="form-label">Password</Label>
                  <Input
                    name="password"
                    type="password"
                    style={InputStyle}
                    placeholder="Enter Password"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.password || ''}
                    invalid={
                      !!(
                        validation.touched.password &&
                        validation.errors.password
                      )
                    }
                  />
                  {validation.touched.password && validation.errors.password ? (
                    <FormFeedback type="invalid">
                      {validation.errors.password}
                    </FormFeedback>
                  ) : null}
                </div>

                <div className="mt-4">
                  <button
                    style={{
                      backgroundColor: primaryColor,
                      borderColor: primaryColor,
                      borderRadius: 30,
                    }}
                    className="btn btn-primary btn-block "
                    type="submit"
                  >
                    Inscription
                  </button>
                </div>

                <div className="mt-4 text-center">
                  <p className="mb-0">
                    En continuant, vous acceptez les
                    <a
                      href="https://www.resellcorner.io/cgu"
                      target="_blank"
                      style={{
                        color: primaryColor,
                        textDecoration: 'underline !important',
                      }}
                      rel="noreferrer"
                    >
                      {' '}
                      Conditions d'utilisation
                    </a>
                  </p>
                </div>
              </Form>
            </div>
            <div className="mt-5 text-center">
              <p>
                Vous avez déjà un compte ?{' '}
                <a
                  href="/login"
                  style={{
                    color: primaryColor,
                    textDecoration: 'underline !important',
                  }}
                >
                  {' '}
                  Connexion{' '}
                </a>{' '}
              </p>
            </div>
          </Col>
          <Col
            className="d-none d-md-inline-block"
            style={{
              padding: 20,
              borderRadius: 10,
              backgroundColor: 'rgba(0, 0, 0, 0.3)',
              overflow: 'hidden',
              flex: 3,
              boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
            }}
          >
            <img
              style={{
                flex: 1,
                width: '100%',
              }}
              src={profile}
              alt="logo"
            />
          </Col>
        </Row>
      </div>
      <AuthFooter />
    </div>
  )
}

export default Register
