import { APIClient } from '../helpers/api_helper'

type TSupllierID = string
export type TProduct = {
  id: string
  asin?: string
  ean?: string
  upc?: string
  amazonUrl?: string
  fbaFee?: number
  amazonPrice?: number
  amazonImgUrl?: string
  category?: string
  country: string
  estimatedSalesPerMonth?: number
  imgUrl: string
  price: number
  profit?: number
  profitPerMonth?: number
  profitPercent?: number
  rank?: number
  roi?: number
  sellerType: string
  supplier: TSupllierID
  supplierImgUrl: string
  supplierPrice: number
  supplierProductName: string
  supplierUrl: string
  supplerCountry: string
  title?: string
  lastUpdateDate?: string
  supplierPriceHT?: number
  supplierPriceTTC?: number
}
class ProductEntity extends APIClient<TProduct> {}
export { ProductEntity }
