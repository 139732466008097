/* eslint-disable react/require-default-props */
import React from 'react'
import {
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Button,
  Container,
} from 'reactstrap'

import { useNavigate } from 'react-router-dom'
import logo from '../../assets/images/logoR.png'
// import lightlogo from '../../assets/images/logolg.png'

const NavigationBar = ({
  connexionButtonTitle,
  connexionButtonLink,
  registerButtonTitle,
  registerButtonLink,
}: {
  connexionButtonTitle?: string
  connexionButtonLink?: string
  registerButtonTitle?: string
  registerButtonLink?: string
}) => {
  const navigate = useNavigate()
  return (
    <Navbar expand="md" style={{ backgroundColor: '#fff', marginBottom: 85 }}>
      <Container
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingTop: '10px',
        }}
      >
        {/* Navbar Brand - Logo */}
        <NavbarBrand href="/" className="d-flex align-items-center">
          <img
            src={logo} // Replace this with your logo path
            alt="Resell Corner"
            style={{ height: '40px', marginRight: '10px' }}
          />
          <span style={{ color: '#555', fontSize: '20px' }}>Resell Corner</span>
        </NavbarBrand>

        {/* Navigation Links */}
        <Nav className="ml-auto" navbar>
          <NavItem>
            <NavLink href="#" style={{ color: '#555', fontWeight: '500' }}>
              Fonctionnalités
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="#" style={{ color: '#555', fontWeight: '500' }}>
              Prix
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="#" style={{ color: '#555', fontWeight: '500' }}>
              Contact
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="#" style={{ color: '#555', fontWeight: '500' }}>
              Tutoriels
            </NavLink>
          </NavItem>
        </Nav>

        {/* Buttons */}
        <div className="ml-auto d-flex">
          {connexionButtonLink && connexionButtonTitle && (
            <Button
              onClick={() => navigate(connexionButtonLink)}
              color="light"
              style={{
                marginRight: '10px',
                fontWeight: '500',
                color: '#000',
                backgroundColor: '##eeeeee',
                borderRadius: '30px',
              }}
            >
              {connexionButtonTitle}
            </Button>
          )}
          {registerButtonTitle && registerButtonLink && (
            <Button
              onClick={() => navigate(registerButtonLink)}
              color="warning"
              style={{
                fontWeight: '500',
                color: '##fff',
                backgroundColor: '#FF9900',
                borderRadius: '30px',
              }}
            >
              {registerButtonTitle}
            </Button>
          )}
        </div>
      </Container>
    </Navbar>
  )
}

export { NavigationBar }
