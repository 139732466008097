import React, { useState, useEffect } from 'react'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap'

// i18n
import { withTranslation } from 'react-i18next'
// Redux
import { Link, useNavigate } from 'react-router-dom'
import { createSelector } from 'reselect'
import { useSelector, useDispatch } from 'react-redux'
import { user } from 'slices/auth/login/reducer'
import * as Store from 'slices'
import withRouter from '../../Common/withRouter'

// users
import user1 from '../../../assets/images/users/avatar-9.jpg'
import {
  getAdminToken,
  switchBackToAdminToken,
} from '../../../helpers/tokenService'

const ProfileMenu = (props: any) => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)
  const [username, setusername] = useState('Admin')
  const userData: any = useSelector(user)
  const isAdmin = userData?.roles?.includes('ADMIN')
  const adminToken = getAdminToken()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const switchToAdmin = async () => {
    if (adminToken) {
      switchBackToAdminToken()
      await dispatch(Store.login.fetchCurrentUser() as any)
    }
  }

  useEffect(() => {
    if (userData?.firstName && userData?.lastName) {
      setusername(userData?.firstName)
    }
  }, [userData])

  // useEffect(() => {
  //   if (localStorage.getItem('authUser')) {
  //     if (process.env.REACT_APP_DEFAULTAUTH === 'firebase') {
  //       const obj = userProfile ? JSON.parse(userProfile) : {}
  //       if (obj?.firstName && obj?.lastName) {
  //         setusername(obj?.displayName)
  //       }
  //     } else if (
  //       process.env.REACT_APP_DEFAULTAUTH === 'fake' ||
  //       process.env.REACT_APP_DEFAULTAUTH === 'jwt'
  //     ) {
  //       const obj = JSON.parse(localStorage.getItem('authUser') || '')
  //       setusername(obj.username)
  //     }
  //   }
  // }, [success])

  return (
    <Dropdown
      isOpen={menu}
      toggle={() => setMenu(!menu)}
      className="d-inline-block"
    >
      <DropdownToggle
        className="btn header-item "
        id="page-header-user-dropdown"
        tag="button"
      >
        <img
          className="rounded-circle header-profile-user"
          src={user1}
          alt="Header Avatar"
        />
        <span className="d-none d-xl-inline-block ms-2 me-1">
          {username || 'admin'}
        </span>
        <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
      </DropdownToggle>
      <DropdownMenu
        style={{
          zIndex: 10000,
        }}
        className="dropdown-menu-end"
      >
        <DropdownItem tag="a" href={`${process.env.PUBLIC_URL}/update-user`}>
          {' '}
          <i className="bx bx-user font-size-16 align-middle me-1" />
          Profil{' '}
        </DropdownItem>
        {isAdmin && (
          <>
            <DropdownItem tag="a" href={`${process.env.PUBLIC_URL}/users`}>
              <i className="bx bx-users font-size-16 align-middle me-1" />
              Liste des utilisateurs
            </DropdownItem>
            <DropdownItem tag="a" href={`${process.env.PUBLIC_URL}/users`}>
              <i className="bx bx-users font-size-16 align-middle me-1" />
              Alertes
            </DropdownItem>
            <DropdownItem tag="a" href={`${process.env.PUBLIC_URL}/users`}>
              <i className="bx bx-users font-size-16 align-middle me-1" />
              Editer une facture
            </DropdownItem>
            <DropdownItem tag="a" href={`${process.env.PUBLIC_URL}/users`}>
              <i className="bx bx-users font-size-16 align-middle me-1" />
              Upload manuel
            </DropdownItem>
            <DropdownItem tag="a" href={`${process.env.PUBLIC_URL}/amazonapi`}>
              Amazon API
            </DropdownItem>{' '}
            <DropdownItem tag="a" href={`${process.env.PUBLIC_URL}/disclaimer`}>
              Admin Message
            </DropdownItem>
          </>
        )}
        {/* <DropdownItem tag="a" href="#">
          <span className="badge bg-success float-end">11</span>
          <i className="bx bx-wrench font-size-16 align-middle me-1" />
          {props.t('Settings')}
        </DropdownItem> */}
        {/* <DropdownItem
          tag="a"
          href={`${process.env.PUBLIC_URL}/auth-lock-screen`}
        >
          <i className="bx bx-lock-open font-size-16 align-middle me-1" />
          {props.t('Lock screen')}
        </DropdownItem> */}
        {adminToken && (
          <>
            <div className="dropdown-divider" />
            <button
              onClick={switchToAdmin}
              type="button"
              className="dropdown-item"
            >
              <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
              <span>Swich to AMIN</span>
            </button>
          </>
        )}
        <div className="dropdown-divider" />
        <Link to="/logout" className="dropdown-item">
          <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
          <span>{props.t('Logout')}</span>
        </Link>
      </DropdownMenu>
    </Dropdown>
  )
}

export default withRouter(withTranslation()(ProfileMenu))
