/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react'
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import * as loginStore from 'slices/auth/login/reducer'
// import { firebaseAuth } from '../../helpers/firebase'
import { AllowedFields, COUNTRY_NAMES, HIDDEN_FIELDS } from 'common/utils'
import toastr from 'toastr'
import { AmazonSellerConnectButton } from 'Components/AmazonSellerConnectButton/AmazonSellerConnectButton'
import * as data from '../../data'
import * as Store from '../../slices'

const AMAZON_SELLER_URL =
  'https://sellercentral.amazon.com/apps/authorize/consent'
const SELLER_APP_CLIENT_ID = ''
const REDIRECT_URI = 'http://localhost:3000/amazonapi'

const getButtons = (planUrl: string) => {
  return [
    {
      title: 'GÉRER MES ABONNEMENTS',
      link: planUrl || '/plan',
    },
    {
      title: 'MES FACTURES',
      link: '/invoices',
    },
  ]
}

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required'),
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  phoneNumber: Yup.string()
    .matches(/^[0-9]+$/, 'Phone number must be only digits')
    .min(10, 'Phone number must be at least 10 digits long')
    .required('Numero de téléphone est obligatoire'),
  // .required('Phone number is required'),
  // roles: Yup.array().of(Yup.string()).required('At least one role is required'),
  // Assuming userId is not part of the form but added programmatically
})

const UpdateProfile = () => {
  const authUser: any = useSelector(loginStore.user)
  const [buttons, setButtons] = useState(getButtons(''))
  // const authUser = JSON.parse(localStorage.getItem('authUser') || '')?.user
  const dispatch = useDispatch()
  const [user, setUser] = useState<any>({
    email: '',
    firstName: '',
    lastName: '',
    companyName: '',
    companyID: '',
    vatId: '',
    phoneNumber: '',
    city: '',
    address: '',
    zipcode: '',
    country: 'FR',
    ...authUser,
  })
  const userId = authUser?._id

  const isProfileCompleted = useSelector(
    Store.login.selectors.isProfileCompleted
  )

  const formik = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: { ...user },
    validationSchema,
    onSubmit: (values: any) => {
      const cleanValues = Object.keys(AllowedFields).reduce((acc, key) => {
        acc[key] = values[key]
        return acc
      }, {})
      console.log('cleanValues', cleanValues)

      data.user
        .put(authUser?._id, cleanValues)
        .then((res) => {
          console.log('res', res)
          toastr.success('Profile updated successfully', 'Profile')
        })
        .catch((err) => {
          toastr.error('Error updating profile', 'Profile')
          console.log('err', err)
        })
    },
  })
  useEffect(() => {
    if (!userId) {
      return
    }
    dispatch(Store.login.fetchCurrentUser() as any)
  }, [userId])

  useEffect(() => {
    if (
      !!authUser?.stripeCustomerId &&
      (authUser?.hasBasicPlan || authUser?.hasPremiumPlan) &&
      authUser?.planStatus === 'active'
    ) {
      data.subscription
        .get('link')
        .then((response) => {
          if (response?.data?.url) {
            setButtons(getButtons(response?.data?.url))
            // window.open(response?.data?.url)
          }
          console.log('response', response)
        })
        .catch((error) => {
          console.log('error', error)
        })
    } else {
      console.log('user dont have basic or premium plan')
    }
  }, [authUser])

  return (
    <div className="page-content">
      <Container fluid>
        <h1>Mettre à jour son profil</h1>
        {!isProfileCompleted ? (
          <Alert key="profileNotCompleted" color="danger">
            Votre profil n'est pas complet, vous ne pourrez pas utiliser la
            plateforme tant que vous ne l'aurez pas complété.
          </Alert>
        ) : null}

        <Row>
          <Col md="6">
            <Form onSubmit={formik.handleSubmit}>
              {Object.keys(AllowedFields).map((userKey) =>
                HIDDEN_FIELDS.includes(userKey) ? null : (
                  <FormGroup key={userKey}>
                    <Label for={userKey}>{AllowedFields[userKey]}</Label>
                    <Input
                      type={userKey === 'country' ? 'select' : 'text'}
                      name={userKey}
                      id={userKey}
                      value={formik.values[userKey] || ''}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      invalid={
                        formik.touched[userKey] && !!formik.errors[userKey]
                      }
                    >
                      {userKey === 'country'
                        ? Object.keys(COUNTRY_NAMES).map((countryKey) => (
                            <option value={countryKey} key={countryKey}>
                              {COUNTRY_NAMES[countryKey]}
                            </option>
                          ))
                        : null}
                    </Input>
                  </FormGroup>
                )
              )}
              <Button
                onClick={async () => {
                  console.log('formik.values', formik.values)
                  console.log('formik.errors', formik.errors)
                  await formik.submitForm()
                }}
                type="submit"
                color="primary"
              >
                METTRE A JOUR MES INFOS
              </Button>
            </Form>
          </Col>
          <Col
            md="6"
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              flex: 1,
              marginTop: 200,
            }}
          >
            <Row>
              <AmazonSellerConnectButton />
            </Row>
            {buttons.map((button) => (
              <Row key={button.title} style={{ margin: 20 }}>
                <a className="btn btn-primary" href={button.link}>
                  {button.title}
                </a>
              </Row>
            ))}
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export { UpdateProfile }
