import React, { useEffect } from 'react'
import './App.css'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import AuthProtected from 'Routes/AuthProtected'
import toastr from 'toastr'
import * as data from 'data'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { authProtectedRoutes, publicRoutes } from './Routes/allRoutes'
import VerticalLayout from './Layouts/VerticalLayout'
import HorizotanlLayout from './Layouts/HorizontalLayout/index'
import './assets/scss/theme.scss'
import NonAuthLayout from './Layouts/NonLayout'

// constants
import { LAYOUT_TYPES } from './Components/constants/layout'
import 'toastr/build/toastr.min.css'
// import fakeBackend from "./helpers/AuthType/fakeBackend";

toastr.options = {
  timeOut: 3000,
  positionClass: 'toast-top-right',
  closeButton: true,
  progressBar: true,
  newestOnTop: true,
  rtl: false,
  preventDuplicates: false,
}

// init firebase backend

// Activating fake backend
// fakeBackend();
const getLayout = (layoutType: any) => {
  let Layout = VerticalLayout
  switch (layoutType) {
    case LAYOUT_TYPES.VERTICAL:
      Layout = VerticalLayout
      break
    case LAYOUT_TYPES.HORIZONTAL:
      Layout = HorizotanlLayout
      break
    default:
      Layout = VerticalLayout
      break
  }
  return Layout
}

const queryClient = new QueryClient()

const App = () => {
  const selectLeadData = createSelector(
    (state: any) => state.Layout,
    (layoutTypes) => layoutTypes
  )
  const { layoutTypes } = useSelector(selectLeadData)

  const Layout = getLayout(layoutTypes)
  useEffect(() => {
    data.user
      .get('disclaimer')
      .then((res) => {
        const { data: _message } = res
        console.log({ _message })

        if (_message.length > 0) {
          localStorage.setItem('disclaimer', _message)
        } else {
          localStorage.removeItem('disclaimer')
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])
  return (
    <Routes>
      {publicRoutes.map((route) => (
        <Route
          path={route.path}
          key={`route_public${route.path}`}
          element={<NonAuthLayout>{route.component}</NonAuthLayout>}
        />
      ))}
      {authProtectedRoutes.map((route) => (
        <Route
          path={route.path}
          key={`route_private${route.path}`}
          element={
            <AuthProtected>
              <Layout>{route.component}</Layout>
            </AuthProtected>
          }
        />
      ))}
    </Routes>
  )
}

const _App = () => (
  <QueryClientProvider client={queryClient}>
    <App />
  </QueryClientProvider>
)

export default _App
