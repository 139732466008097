import React from 'react'
import toastr from 'toastr'

const copyToClipboard = (text: string) => {
  navigator.clipboard.writeText(text)
  toastr.success('Texte copié', 'Succès', {
    hideDuration: 100,
  })
}

const CopiableCell = (params: any) => {
  const { value } = params as any
  if (!value) {
    return null
  }
  return (
    <button
      className="btn "
      type="button"
      onClick={() => copyToClipboard(value)}
    >
      <i className="mdi mdi-content-copy" />
      {value}
    </button>
  )
}

export { CopiableCell }
