import React, { useEffect, useMemo } from 'react'
import { Button, Container } from 'reactstrap'
import { AgGridReact } from 'ag-grid-react'
import { ColDef } from '@ag-grid-community/core'
import * as data from 'data'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import toastr from 'toastr'
import { CopiableCell } from 'Components/CopiableCell/CopiableCell'
import { useSelector, useDispatch } from 'react-redux'
import * as Store from 'slices'
import * as loginStore from 'slices/auth/login/reducer'
import { useNavigate } from 'react-router-dom'

interface SupplierAffilizData {
  shopId: string
  shopName: string
  supplierId: string
  _id: string
}

const SupplierAffiliz = () => {
  const navigate = useNavigate()

  const isUserAdmin = useSelector(loginStore.selectors.isAdmin)
  useEffect(() => {
    if (!isUserAdmin) {
      navigate('/dashboard')
    }
  }, [isUserAdmin, navigate])
  const dispatch = useDispatch()
  // Fetch data
  const { data: affilizData, isLoading } = useQuery<SupplierAffilizData[]>({
    queryKey: ['supplierAffiliz'],
    queryFn: () => data.supplier.get('affiliz').then((res) => res.data),
  })
  const supplierIndexes: any = useSelector(Store.suppliers.selectors.getIndexes)
  const suppliers: any[] = Object.values(supplierIndexes)

  const usedAffilizIds = useMemo(() => {
    return suppliers
      .filter(
        (supplier) =>
          supplier.affilizId !== null &&
          supplier.affilizId !== '' &&
          supplier.affilizId !== undefined
      )
      .map((supplier) => supplier.affilizId)
  }, [suppliers])

  useEffect(() => {
    dispatch(Store.suppliers.fetchSuppliers() as any)
  }, [])

  console.log('====================================')
  console.log(usedAffilizIds)
  console.log('====================================')

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      minWidth: 100,
      resizable: true,
      filter: true,
    }
  }, [])

  const fields = [
    {
      field: '_id',
      headerName: 'Shop ID',
      cellRenderer: (params) => <CopiableCell {...params} />,
    },
    {
      field: 'shopName',
      headerName: 'Shop Name',
    },
    {
      field: 'country',
      headerName: 'Country',
      editable: true,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: [undefined, 'FR', 'UK', 'IT', 'ES', 'DE'],
        cellRenderer: (params) => {
          return params.value || 'Selectionner un pays...'
        },
      },
      valueFormatter: (params: any) => {
        const countryMap = {
          undefined: 'Selectionner un pays...',
          FR: 'France',
          UK: 'Royaume-Uni',
          IT: 'Italie',
          ES: 'Espagne',
          DE: 'Allemagne',
        }
        return countryMap[params.value] || params.value
      },
    },
    {
      field: 'type',
      headerName: 'Type',
      editable: true,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: [undefined, 'pro', 'supplier'],
        cellRenderer: (params) => {
          return params.value || 'Selectionner un type...'
        },
      },
      valueFormatter: (params: any) => {
        const typeMap = {
          undefined: 'Selectionner un type...',
          pro: 'Professionel',
          supplier: 'Marchant',
        }
        return typeMap[params.value] || params.value
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      cellRenderer: (params) => {
        const openLink = () => {
          // window.open(`/supplier/${params.data.supplierId}`, '_blank')
        }

        const handleAddSupplier = () => {
          const shopId = params.data._id
          const { shopName } = params.data
          const { type } = params.data
          const { country } = params.data

          if (shopId && shopName && type && country) {
            console.log('====================================')
            console.log(shopId, shopName, type, country)
            const _data = {
              affilizId: shopId,
              shopName,
              name: shopName,
              type,
              country,
              isActive: false,
              lastUpdateDate: new Date(),
              hasAffiliz: true,
            }
            dispatch(Store.suppliers.createSupplierAffiliz(_data) as any)
            console.log('====================================')
          }
          // if (
          //   window.confirm('Êtes-vous sûr de vouloir supprimer cet élément ?')
          // ) {
          //   // deleteMutation.mutate(params.data._id)
          // }
        }

        return (
          <div className="hstack gap-2">
            <Button color="success" size="sm" onClick={handleAddSupplier}>
              <i className="bx bx-plus" /> Ajouter
            </Button>
          </div>
        )
      },
    },
  ]

  return (
    <div className="page-content" style={{ height: '100vh' }}>
      <h1>Supplier Affiliz</h1>
      <div className="hstack gap-3 p-3">
        <Button color="primary" href="/supplier-affiliz/create">
          Ajouter un supplier affiliz
        </Button>
      </div>
      <div className="ag-theme-quartz" style={{ height: '85%' }}>
        <AgGridReact
          rowData={
            (affilizData || [])?.filter(
              (item) => !usedAffilizIds?.includes(item._id) && item._id !== null
            ) || []
          }
          columnDefs={fields as any}
          defaultColDef={defaultColDef as any}
          pagination
          paginationPageSize={50}
          loading={isLoading}
        />
      </div>
    </div>
  )
}

export { SupplierAffiliz }
