/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect, useMemo, useRef } from 'react'
import {
  Button,
  Row,
  Label,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
} from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/styles/ag-grid.css' // Mandatory CSS required by the Data Grid
import 'ag-grid-community/styles/ag-theme-quartz.css'

import { TUser } from 'data/UserEntity'
import { ColDef, ModuleRegistry } from '@ag-grid-community/core'

import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'
import toastr from 'toastr'
import Select from 'react-select'
import * as Store from 'slices'
import { ValueFormatterParams } from 'ag-grid-community'
import { useNavigate } from 'react-router-dom'
import { CopiableCell } from 'Components/CopiableCell/CopiableCell'
import { switchToUserToken } from '../../helpers/tokenService'
import * as data from '../../data'
import * as store from '../../slices'

ModuleRegistry.registerModules([ClientSideRowModelModule as any])

export const selectFieldsOptions = {
  planStatus: [
    'active',
    'inactive',
    'canceled',
    'trialing',
    'past_due',
    'unpaid',
  ],
  plan: ['FREE', 'BASIC', 'PREMIUM'],
  actualPlan: ['FREE', 'BASIC', 'PREMIUM'],
  roles: ['USER', 'CUSTOMER', 'ADMIN', 'TESTER'],
}

export const selectFieldsIcon = {
  plan: {
    FREE: 'mdi mdi-account',
    BASIC: 'mdi mdi-cash-usd',
    PREMIUM: 'mdi mdi-gold',
  },
  roles: {
    USER: 'mdi mdi-account',
    ADMIN: 'mdi mdi-police-badge',
    TESTER: 'mdi mdi-account-check',
  },
  planStatus: {
    active: 'mdi mdi-check',
    inactive: 'mdi mdi-close',
    canceled: 'mdi mdi-close',
    trialing: 'mdi mdi-clock',
    past_due: 'mdi mdi-close',
    unpaid: 'mdi mdi-close',
  },
}

const SelectCell = ({ params, defaultValue, isMulti = false }) => {
  const dispatch = useDispatch()
  const [edit, setEdit] = useState(false)
  const { value, data: _data, colDef } = params as any
  const itemValues = Array.isArray(value)
    ? value.length === 0
      ? defaultValue
      : value
    : value || defaultValue
  const [values, setValues] = useState(itemValues)

  const name = colDef.field
  const options = selectFieldsOptions[name]
  const onChange = (e) => {
    if (Array.isArray(e)) {
      const _values = e.map((item) => item.value)
      setValues(_values)
    } else {
      const _values = e.value
      setValues(_values)
    }
  }
  const id = _data?._id

  const onSubmit = () => {
    data.user
      .put(id, { [name]: values })
      .then((res) => {
        console.log('res', res)
        toastr.success('La mise à jour a été effectuée', name.toUpperCase())
        // dispatch(Store.login.fetchUser(id) as any)
        dispatch(Store.user.fetchUserById(id) as any)
      })
      .catch((err) => {
        toastr.error('Erreur pendant la mise à jour ', name.toUpperCase())
        console.log('err', err)
      })
      .finally(() => {
        setEdit(false)
      })
  }
  const icons = selectFieldsIcon[name]

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        textAlign: 'center',
        justifyContent: 'center',
      }}
      onDoubleClick={() => {
        setEdit(!edit)
      }}
    >
      <Modal size="xs" fullscreen="sm" isOpen={edit}>
        <ModalHeader>
          <Label>{name.toUpperCase()}</Label>
        </ModalHeader>
        <ModalBody>
          <Select
            styles={{
              control: (base) => ({
                ...base,
                backgroundColor: 'white',
              }),
              option(base, props) {
                return {
                  ...base,
                  color: 'black',
                  backgroundColor: props.isSelected ? 'lightgrey' : 'white',
                }
              },
            }}
            name={name}
            onChange={onChange}
            isMulti={isMulti}
            defaultValue={
              Array.isArray(itemValues)
                ? itemValues.map(
                    (role) =>
                      ({
                        value: role,
                        label: role,
                      }) as any
                  )
                : {
                    value: itemValues,
                    label: itemValues,
                  }
            }
            options={options.map(
              (role) =>
                ({
                  value: role,
                  label: role,
                }) as any
            )}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={onSubmit}>
            Valider
          </Button>
          <Button color="secondary" onClick={() => setEdit(false)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
      {Array.isArray(itemValues) ? (
        itemValues.map((role) => (
          <div key={role}>
            |{' '}
            <i
              style={{
                fontSize: '20px',
              }}
              className={icons[role]}
            />{' '}
            |
          </div>
        ))
      ) : (
        <div>{itemValues}</div>
      )}
    </div>
  )
}

const UsersTable = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const users: TUser[] = useSelector(
    store.user.selectors.getAllCollection
  ) as TUser[]
  const [numberOfRows, setNumberOfRows] = useState(users.length)
  useEffect(() => {
    dispatch(Store.user.fetchUsers() as any)
    return () => {}
  }, [])

  const valueSetter = (params, overwriteValue?: any) => {
    console.log('params', params.oldValue, params.newValue)

    if (params.oldValue === params.newValue) {
      return false
    }
    const name = params.colDef.field
    const id = params.data._id
    const values = overwriteValue || params.newValue
    console.log('params', params)

    return data.user
      .put(id, { [name]: values })
      .then((res) => {
        console.log('res', res)
        toastr.success('La mise à jour a été effectuée', name.toUpperCase())
        dispatch(Store.user.fetchUserById(id) as any)
        return true
      })
      .catch((err) => {
        toastr.error('Erreur pendant la mise à jour ', name.toUpperCase())
        console.log('err', err)
        return false
      })
  }
  const columnDefs = [
    {
      field: 'id',
      headerName: 'ID',
      editable: false,
      cellRenderer: (params) => (<CopiableCell {...params} />) as any,
    },
    {
      field: 'email',
      headerName: 'Email',
      editalbe: false,
      cellRenderer: (params) => (<CopiableCell {...params} />) as any,
    },
    {
      field: 'firstName',
      valueSetter,
      headerName: 'First Name',
    },
    {
      field: 'companyName',
      valueSetter,
      headerName: 'Company Name',
    },
    {
      field: 'phoneNumber',
      valueSetter,
      headerName: 'Phone Number',
      cellRenderer: (params) => (<CopiableCell {...params} />) as any,
    },
    {
      field: 'stripeCustomerId',
      headerName: 'Stripe Customer ID',
      valueSetter,
      cellRenderer: (params) => (<CopiableCell {...params} />) as any,
    },
    {
      field: 'createdAt',
      filter: 'agDateColumnFilter',
      headerName: 'Date de création',
      editable: false,
    },
    {
      field: 'plan',
      headerName: 'Abonnement',
      editable: false,
      cellRenderer: (params) =>
        (<SelectCell defaultValue="FREE" params={params} />) as any,
    },
    {
      field: 'planStatus',
      headerName: 'Statut Abonnement',
      editable: false,
      cellRenderer: (params) =>
        (<SelectCell defaultValue="inactive" params={params} />) as any,
    },
    {
      field: 'planStartAt',
      valueSetter,
      filter: 'agDateColumnFilter',
      headerName: 'Début Abonnement',
      cellEditor: 'agDateCellEditor',
    },
    {
      field: 'planEndAt',
      filter: 'agDateColumnFilter',
      headerName: 'Fin Abonnement',
      cellEditor: 'agDateCellEditor',
      valueSetter,
    },
    {
      field: 'roles',
      headerName: 'Roles',
      editable: false,
      cellRenderer: (params) =>
        (<SelectCell defaultValue={['USER']} params={params} isMulti />) as any,
    },
    {
      field: 'Switch',
      headerName: 'Switch',
      cellRenderer: ((params): any => {
        const { data: _data } = params as any
        return (
          <div>
            <button
              type="button"
              onClick={() => {
                const id = _data._id
                data.user
                  .get(`switch/${_data._id}`)
                  .then(async (res) => {
                    const token = res.data
                    console.log('token', token)
                    switchToUserToken(token)
                    await dispatch(Store.login.fetchUser(id) as any)
                    navigate('/dashboard')
                    // toastr.success('La mise à jour a été effectuée', 'ACTIVE')
                    // dispatch(Store.user.fetchUserById(id) as any)
                  })
                  .catch((err) => {
                    toastr.error('Erreur pendant la mise à jour ', 'ACTIVE')
                    console.log('err', err)
                  })
              }}
              className="btn btn-secondary"
            >
              Switch
            </button>
            {/* <input
              type="checkbox"
              checked={data.active}
              onChange={() => {
                const id = data._id
                data.user
                  .put(id, { active: !data.active })
                  .then((res) => {
                    console.log('res', res)
                    toastr.success('La mise à jour a été effectuée', 'ACTIVE')
                    dispatch(Store.user.fetchUserById(id) as any)
                  })
                  .catch((err) => {
                    toastr.error('Erreur pendant la mise à jour ', 'ACTIVE')
                    console.log('err', err)
                  })
              }}
            /> */}
          </div>
        )
      }) as any,
    },
  ]

  const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), [])
  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      minWidth: 100,
      editable: true,
      resizable: true,
      height: 500,
      filter: true,
    }
  }, [])

  return (
    <div
      className="page-content"
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <h2> Liste des utlisateurs</h2>({numberOfRows} utilisateurs)
      <div style={containerStyle}>
        <div
          className="ag-theme-quartz"
          style={{
            marginBottom: 50,
            height: '100%',
          }}
        >
          {/* <gridOptions.api.getDisplayedRowCount() */}
          <AgGridReact<any>
            rowData={users}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef as any}
            enterNavigatesVertically
            enterNavigatesVerticallyAfterEdit
            onFilterChanged={(e) =>
              setNumberOfRows(e.api.getDisplayedRowCount())
            }
            onCellEditingStopped={async (e) => {
              console.log('onCellEditingStopped', e)
              const name = e.colDef.field
              console.log(
                'name',
                name,
                ['planStartAt', 'planEndAt'].includes(name as any)
              )

              if (['planStartAt', 'planEndAt'].includes(name as any)) {
                console.log('e.value', e.newValue)

                const date = new Date(e.newValue).toISOString()
                console.log('date', date)

                await valueSetter(e, date)
              }
            }}

            // onGridReady={onGridReady}
          />
        </div>
      </div>
    </div>
  )
}

export default UsersTable
