import axios from 'axios'
import { getToken } from './tokenService'

// default
axios.defaults.baseURL =
  process.env.REACT_APP_API_URL || 'http://localhost:9000'

// content type
axios.defaults.headers.post['Content-Type'] = 'application/json'
// content type
// let authUser: any = (localStorage.getItem("authUser"));

// intercepting to capture errors
axios.interceptors.request.use(
  function (config: any) {
    // Do something before request is sent
    const token = getToken()

    config.headers.Authorization = token ? `Bearer ${token}` : ''
    return config
  },
  function (error: any) {
    // Do something with request error
    return Promise.reject(error)
  }
)
axios.interceptors.response.use(
  function (response: any) {
    return response.data ? response.data : response
  },
  function (error: any) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    let message: any
    switch (error.status) {
      case 500:
        message = 'Internal Server Error'
        break
      case 401:
        message = 'Invalid credentials'
        break
      case 404:
        message = 'Sorry! the data you are looking for could not be found'
        break
      default:
        message = error?.response?.data?.message || error.message || error
    }
    return Promise.reject(message)
  }
)
/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token: any) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`
}

/**
 * API Client
 */

/**
 * API Client
 */
export type TParams<T> = {
  [index in keyof T | 'limit']: any
}

class APIClient<T> {
  private baseUrl: string

  constructor(baseUrl: string = '') {
    this.baseUrl = baseUrl
  }
  /**
   * Fetches data from given url
   */

  //  get = (this.getUrl(url), params) => {
  //   return axios.get(this.getUrl(url), params);
  // };
  getUrl = (url: any) => {
    return `${this.baseUrl ? `${this.baseUrl}/` : ''}${url}`
  }

  get = (url: any, params?: TParams<T>) => {
    let response: any
    const paramKeys: any = []
    if (params) {
      Object.keys(params).map((key) => {
        paramKeys.push(`${key}=${params[key]}`)
        return paramKeys
      })

      const queryString =
        paramKeys && paramKeys.length ? paramKeys.join('&') : ''
      response = axios.get(`${this.getUrl(url)}?${queryString}`, params)
    } else {
      response = axios.get(`${this.getUrl(url)}`, params)
    }

    return response
  }

  fetch = (url: any, params: any) => {
    return this.get(this.getUrl(url), params)
  }

  findByConditions = (url: any, data: any) => {
    return axios.post(this.getUrl(url), data)
  }

  /**
   * post given data to url
   */
  create = (url: any, data: any) => {
    return axios.post(this.getUrl(url), data)
  }

  post = (url: any, data: any) => {
    return axios.post(this.getUrl(url), data)
  }

  /**
   * Updates data
   */
  update = (url: any, data: any) => {
    return axios.patch(this.getUrl(url), data)
  }

  put = (url: any, data: any) => {
    return axios.put(this.getUrl(url), data)
  }

  /**
   * Delete
   */
  delete = (url: any, config?: any) => {
    return axios.delete(this.getUrl(url), { ...(config || {}) })
  }
}

const getLoggedinUser = () => {
  const user = localStorage.getItem('authUser')
  if (!user) {
    return null
  }
  return JSON.parse(user)
}

export { APIClient, setAuthorization, getLoggedinUser }
