import {
  postFakeLogin,
  postJwtLogin,
  resetPasswordApi,
  sendSupportEmail,
  sendVerificationEmail,
  verfyEmail,
} from 'helpers/fakebackend_helper'
// import { TUser } from 'data/UserEntity'
import * as data from 'data'
// import { firebaseAuth } from '../../../helpers/firebase'
import { setAuthorization } from 'helpers/api_helper'
import { errorMessage } from 'jest-validate/build/errors'
import {
  loginSuccess,
  apiError,
  logoutUserSuccess,
  resetLoginFlag,
} from './reducer'
import { redirectRoutes } from '../../../Routes/redirectRoutes'
import { loginErrorMessage } from '../../../common/errorMessages'

export const fetchUser =
  (userId: string, cb?: () => void) => async (dispatch: any) => {
    try {
      const response = await data.user.get(userId)

      dispatch(loginSuccess(response.data))
      if (cb) {
        cb()
      }
    } catch (error) {
      dispatch(apiError(error))
    }
  }

export const fetchCurrentUser = () => async (dispatch: any) => {
  try {
    const response = await data.user.get('current')
    dispatch(loginSuccess(response.data))
  } catch (error) {
    dispatch(apiError(error))
  }
}

export const emailValidation =
  (token: string, navigate: any) => async (dispatch: any) => {
    try {
      const response = await verfyEmail({ token })
      if (response.data?._id) {
        localStorage.setItem('authUser', JSON.stringify(response.data))
        localStorage.setItem('authToken', response.token)
        dispatch(loginSuccess(response.data))
        if (
          response.data.isProfileCompleted &&
          response.data.isProfileCompleted === false
        ) {
          navigate(redirectRoutes.UPDATE_USER)
        } else {
          navigate(redirectRoutes.DASHBOARD)
        }
      } else {
        throw new Error('Invalid token')
      }
    } catch (error: any) {
      console.log({ error })

      dispatch(apiError(error.message))
      navigate(redirectRoutes.LOGIN)
    }
  }

export const loginuser =
  (user: any, navigate: any) => async (dispatch: any) => {
    try {
      const response = await postJwtLogin({
        email: user.email,
        password: user.password,
      })

      if (response?.data?.isEmailVerified === false) {
        await sendVerificationEmail({ userId: response?.data._id })
        dispatch(apiError(loginErrorMessage.EMAIL_NOT_VERIFIED))
        return
      }

      let redirectRoute: string = ''

      if (response?.data?.isProfileCompleted === false) {
        redirectRoute = redirectRoutes.UPDATE_USER
      } else {
        redirectRoute = redirectRoutes.DASHBOARD
      }

      localStorage.setItem('authUser', JSON.stringify(response.data))
      localStorage.setItem('authToken', response.token)

      dispatch(loginSuccess(response.data))
      navigate(redirectRoute)
    } catch (error: any) {
      console.log('loginuser error', error)
      dispatch(
        apiError("Le compte n'existe pas ou le mot de passe est incorrect")
      )
    }
  }

export const logoutUser = () => async (dispatch: any) => {
  try {
    localStorage.removeItem('authUser')
    localStorage.removeItem('isProfileCompleted')
    localStorage.removeItem('authToken')
    if (process.env.REACT_APP_DEFAULTAUTH === 'firebase') {
      // const response = firebaseAuth.signOut()
      dispatch(logoutUserSuccess(true))
    } else {
      dispatch(logoutUserSuccess(true))
    }
  } catch (error) {
    console.log('logoutUser error', error)

    // dispatch(apiError(error))
  }
}

export const resetLoginMsgFlag = () => {
  try {
    const response = resetLoginFlag()
    return response
  } catch (error) {
    return error
  }
}

export const resetPassword =
  ({ id, token, password }: any) =>
  async () => {
    try {
      const response = await resetPasswordApi({ id, token, password })

      return true
    } catch (error: any) {
      console.log('error', error.message)

      return false
    }
  }

export const contactSupport = (_data: any) => async () => {
  try {
    const response = await sendSupportEmail(_data)
    console.log('response', response)

    return true
  } catch (error: any) {
    console.log('error', error.message)
    return false
  }
}

// export const socialLogin =
// (type: any, navigate: any) => async (dispatch: any) => {
// try {
//   let response: any
//   if (process.env.REACT_APP_DEFAULTAUTH === 'firebase') {
//     response = firebaseAuth.socialLoginUser(type)
//   }
//   const socialdata = await response
//   if (socialdata) {
//     sessionStorage.setItem('authUser', JSON.stringify(socialdata))
//     dispatch(loginSuccess(socialdata))
//     navigate('/dashboard')
//   }
// } catch (error) {
//   dispatch(apiError(error))
// }
// }
